<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('交易单.订单编号')" prop="orderId">
                <a-input v-model="queryParam.orderId" @pressEnter="handleQuery" :placeholder="$t('通用.输入.请输入')+$t('交易单.订单编号')" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('交易单.三方订单号')" prop="extraOrderNo">
                <a-input v-model="queryParam.extraOrderNo" @pressEnter="handleQuery" :placeholder="$t('通用.输入.请输入')+$t('交易单.三方订单号')" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('交易单.三方支付流水号')" prop="extraPayNo">
                <a-input v-model="queryParam.extraPayNo" @pressEnter="handleQuery" :placeholder="$t('通用.输入.请输入')+$t('交易单.三方支付流水号')" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('交易单.支付状态')" prop="orderStatusList">
                <a-select  mode="multiple" :placeholder="$t('通用.输入.请选择')+$t('交易单.支付状态')"
                           style="width: 100%" v-model="queryParam.payStatusList" allowClear>
                  <a-select-option v-for="(item, index) in this.customDict.PayStatusEnum" :value="item.type"
                                   :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('交易单.交易类型')" prop="tradeType">
                <a-select   :placeholder="$t('通用.输入.请选择')+$t('交易单.交易类型')"
                           style="width: 100%" v-model="queryParam.tradeType" allowClear>
                  <a-select-option v-for="(item, index) in this.customDict.TradeTypeEnum" :value="item.type"
                                   :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('销售订单.下单开始时间')" prop="createBeginTime">
                <a-date-picker style="width: 100%" v-model="queryParam.createBeginTime"
                               :show-time="{defaultValue: moment('00:00:00', 'HH:mm:ss')}"
                               valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('销售订单.下单结束时间')" prop="createEndTime">
                <a-date-picker style="width: 100%" v-model="queryParam.createEndTime"
                               :show-time="{defaultValue: moment('23:59:59', 'HH:mm:ss')}"
                               valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />{{$t('通用.按钮.查询')}}</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />{{$t('通用.按钮.重置')}}</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
        <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
          <img alt="example" style="width: 100%" :src="previewUrl" />
        </a-modal>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="handleExport" v-hasPermi="['order:payment:export']">
          <a-icon type="download" />{{$t('通用.按钮.导出')}}
        </a-button>
        <a-button type="primary" :disabled="multiple"  @click="handleSelectExport" v-hasPermi="['order:payment:export']">
          <a-icon type="download" />{{$t('通用.按钮.选中导出')}}
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <order-detail-form
        ref="orderDetailForm"
      />
      <refund-detail-form
        ref="refundDetailForm"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        size="small"
        @change="handleSortChange"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="payStatus" slot-scope="text, record">
           <custom-dict-tag :options="customDict.PayStatusEnum" :value="record.payStatus"/>
        </span>
        <span slot="payType" slot-scope="text, record">
            <!-- 重写自定义了个字典组件-->
           <custom-dict-tag :options="customDict.OrderPayTypeEnum" :value="record.payType"/>
        </span>
        <span slot="tradeType" slot-scope="text, record">
           <custom-dict-tag :options="customDict.TradeTypeEnum" :value="record.tradeType"/>
        </span>
        <span slot="orderType" slot-scope="text, record">
           <custom-dict-tag :options="customDict.OrderTypeEnum" :value="record.orderType"/>
        </span>
        <span slot="orderId" slot-scope="text, record">
          <a @click="$refs.orderDetailForm.handleDetail(record.orderId)">{{record.orderId}}</a>
        </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="handleRefund(record.orderId)" v-if="record.tradeType===1&&record.payStatus===4" v-hasPermi="['order:payment:refund']">
            <a-icon type="edit" />{{$t('售后订单.退款')}}
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :pageSizeOptions="pageSizeOptions"
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => getTotal(total)"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pagePayment,paymentRefund } from '@/api/finance/payment'
import CustomDictTag from "@/components/DictCustomTag";
import {mapGetters} from 'vuex'
import { tableMixin } from '@/store/table-mixin'
import moment from 'moment';
import orderDetailForm from '@/views/order/sell/modules/detailForm'
import refundDetailForm from '@/views/order/refund/modules/detailForm'
import {pageUser} from "@/api/invoice/user";

export default {
  name: 'Payment',
  components: {
    CustomDictTag,
    orderDetailForm,
    refundDetailForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      pageSizeOptions: ['10', '20', '50', '100'],
      filteredInfo: {},
      sortedInfo : {},
      selectedRowKeys: [],
      selectedRows: [],
      //预览封面
      previewImageVisible: false,
      previewUrl: false,
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        parentId: undefined,
        payChannel: undefined,
        payStatusList: [],
        orderId: undefined,
        orderType: undefined,
        userId: undefined,
        clientIp: undefined,
        payType: undefined,
        tradeType: undefined,
        extraOrderNo: null,
        extraPayNo: null,
        payStatus: undefined,
        payTime: undefined,
        isValid: undefined,
        expireTime: undefined,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: this.$t('交易单.交易单号'),
          dataIndex: 'id',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          align: 'center'
        },
        {
          title: this.$t('交易单.订单编号'),
          sorter: 1,
          scopedSlots: { customRender: 'orderId' },
          sortDirections: ['descend', 'ascend'],
          dataIndex: 'orderId',
          align: 'center'
        },
        {
          title: this.$t('交易单.ip'),
          dataIndex: 'clientIp',
          align: 'center'
        },
        {
          title: this.$t('交易单.支付方式'),
          dataIndex: 'payType',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          scopedSlots: { customRender: 'payType' },
          align: 'center'
        },
        {
          title: this.$t('交易单.交易类型'),
          dataIndex: 'tradeType',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          scopedSlots: { customRender: 'tradeType' },
          align: 'center'
        },
        {
          title: this.$t('交易单.支付金额'),
          dataIndex: 'cashAmount',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          ellipsis: true,
          align: 'center'
        },
        /*{
          title: this.$t('交易单.账期金额'),
          dataIndex: 'accountAmount',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('交易单.手续费'),
          dataIndex: 'fee',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: this.$t('交易单.三方订单号'),
          dataIndex: 'extraOrderNo',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('交易单.三方支付流水号'),
          dataIndex: 'extraPayNo',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          align: 'center'
        },
        {
          title: this.$t('交易单.支付状态'),
          scopedSlots: { customRender: 'payStatus' },
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          dataIndex: 'payStatus',
          align: 'center'
        },
        {
          title: this.$t('交易单.交易完成时间'),
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          dataIndex: 'payTime',
          align: 'center'
        },
        {
          title: this.$t('交易单.创建时间'),
          dataIndex: 'createTime',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('交易单.过期时间'),
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          dataIndex: 'expireTime',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '5%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  methods: {
    moment,
    getTotal(total) {
      return this.$t('通用.文本.共')+total+this.$t('通用.文本.条')
    },
    /** 查询交易单列表 */
    getList () {
      this.loading = true
      pagePayment(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        parentId: undefined,
        payChannel: undefined,
        payStatusList: [],
        orderId: undefined,
        orderType: undefined,
        userId: undefined,
        clientIp: undefined,
        payType: undefined,
        tradeType: undefined,
        cashAmount: undefined,
        accountAmount: undefined,
        fee: undefined,
        payInfo: undefined,
        extraOrderNo: undefined,
        extraPayNo: undefined,
        payStatus: undefined,
        payTime: undefined,
        isValid: undefined,
        expireTime: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    handleSortChange(pagination, filters, sorter) {
      this.filteredInfo = filters
      this.sortedInfo = sorter
      if (sorter.order) {
        /** 排序字段 sortField **/
        /** 排序方式  sortOrder**/
        this.queryParam.sortField = sorter.columnKey
        this.queryParam.sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc'
        this.getList()
      } else {
        this.queryParam.sortField = '';
        this.queryParam.sortOrder = ''
        this.getList()
      }
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    handleRecordPreview(url) {
      this.previewUrl = url
      this.previewImageVisible = true
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    handleRefund(id) {
      var that = this
      let message = this.$t('售后订单.退款成功');
      this.$confirm({
        title: this.$t('售后订单.是否确认退款'),
        content: this.$t('售后订单.是否确认退款'),
        onOk () {
          paymentRefund({"id":id}).then(response => {
            that.getList()
            that.$message.success(
              message,
              3
            )
          })
        },
        onCancel () {}
      })
    },

    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: this.$t('通用.文本.是否确认导出'),
        content: this.$t('通用.文本.此操作将导出当前条件下所有数据而非选中数据'),
        onOk () {
          that.download('order/payment/export',
            that.queryParam
          , `交易单_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    },
    handleSelectExport () {
      var that = this
      let queryParam = {"idList": this.ids}
      this.$confirm({
        title: this.$t('通用.文本.是否确认导出'),
        content: this.$t('通用.文本.此操作将导出选中数据'),
        onOk () {
          that.download('order/payment/export', queryParam, `交易单_${new Date().getTime()}.xlsx`)
          that.ids = []
          that.multiple = true;
          that.selectedRowKeys=[]
        },
        onCancel () {
          that.ids = []
          that.multiple = true;
          that.selectedRowKeys=[]
        }
      })
    }
  }
}
</script>